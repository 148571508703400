import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, LinkBox, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Про Нас | Arena Футбольний навчальний центр
			</title>
			<meta name={"description"} content={"Arena – Де пристрасть відповідає точності"} />
			<meta property={"og:title"} content={"Про Нас | Arena Футбольний навчальний центр"} />
			<meta property={"og:description"} content={"Arena – Де пристрасть відповідає точності"} />
			<link rel={"shortcut icon"} href={"https://ua.tryzubtraditions.com/img/5043402.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://ua.tryzubtraditions.com/img/5043402.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://ua.tryzubtraditions.com/img/5043402.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://ua.tryzubtraditions.com/img/5043402.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://ua.tryzubtraditions.com/img/5043402.png"} />
			<meta name={"msapplication-TileImage"} content={"https://ua.tryzubtraditions.com/img/5043402.png"} />
			<meta name={"msapplication-TileColor"} content={"https://ua.tryzubtraditions.com/img/5043402.png"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="80px 0 80px 0"
			lg-padding="60px 0 60px 0"
			md-padding="40px 0 40px 0"
			sm-padding="30px 0 30px 0"
			background="#46728a"
		>
			<Box margin="0px 0px 32px 0px" display="flex" flex-direction="column" align-items="flex-start">
				<Text
					font="--headline1"
					color="--light"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 0px 0px"
				>
					Про Нас
				</Text>
				<Text font="--lead" color="--light" max-width="900px">
					Візьміть в серце вершини Футтальнян, нанилнян, і розкриває етос, який визначає нас.Пориньте себе в свою прихильність до футболу, наших гравців та нашого бачення майбутнього спорту.Ласкаво просимо до місця, де кожен удар, кожен спринт і кожна мета - це крок до величі.{"\n\n"}
				</Text>
			</Box>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://ua.tryzubtraditions.com/img/2.jpg"
						border-radius="24px"
						max-height="456px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						sm-max-height="213px"
						display="block"
						position="static"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--light">
							Наша філософія{"\n\n"}
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--light">
							У вершині ми вважаємо, що кожен гравець, незалежно від своєї відправної точки, має піку потенціалу, який чекає на виявлення.Наша місія полягає в тому, щоб направити їх до цієї вершини, пропонуючи середовище, де навички посилюються, побудований персонаж, і мрії реалізуються.{"\n\n"}
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--light">
							Наше оточення{"\n\n"}
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--light">
							Розташована серед природної краси, вершина Футтальний наничальня, надає безтурботну фон для суворих тренувань.Поєднання спокою та інтенсивності гарантує, що наші гравці можуть зосередитись на своїй грі з неперевершеною чіткістю.{"\n\n"}
						</Text>
					</Box>
				</Box>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://ua.tryzubtraditions.com/img/3.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://ua.tryzubtraditions.com/img/4.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--light">
							Досконалість навчання{"\n\n"}
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--light">
							Наша навчальна програма - це наша гордість.Розроблений експертами та надається пристрасними тренерами, навчальна програма вершини безперешкодно зливає фундаментальні навички з передовими методами.Ця суміш гарантує, що гравці завжди розвиваються, завжди вдосконалюються.{"\n\n"}
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--light">
							Спільнота та культура{"\n\n"}
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--light">
							Крім навчання, вершина - це громада.Це місце, де гравці збираються разом, утворюючи зв’язки, що виходять за межі кроку.Наша культура відзначає різноманітність, інклюзивність та спільну пристрасть до прекрасної гри.{"\n\n"}
						</Text>
					</Box>
				</Box>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://ua.tryzubtraditions.com/img/5.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://ua.tryzubtraditions.com/img/6.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--light">
							Наша обіцянка{"\n\n"}
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--light">
							Кожному гравцеві, який проходить через наші двері, ми обіцяємо непохитну підтримку, найвищу підготовку та прихильність до ваших футбольних мрій.У вершині ми не просто тренуємо гравців - ми формуємо майбутні футбольні легенди.{"\n\n"}
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--light">
							Приєднуйтесь до нас у нашій подорожі{"\n\n"}
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--light">
							Незалежно від того, що ви тільки починаєте свою футбольну подорож або хочете підняти свою гру на професійні висоти, вершина - це ваше місце призначення.Разом давайте намалюємо курс до зеніту вашого футбольного потенціалу.{"\n\n"}
						</Text>
					</Box>
				</Box>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://ua.tryzubtraditions.com/img/7.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="none"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
			</LinkBox>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"652fe240cd81ee001f7dd8c4"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});